import { FC, MutableRefObject, useCallback } from 'react';
import {
  AltenarEventOdd,
  CMSComponentAltenarPopularBets
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarPopularBets: FC<CMSComponentAltenarPopularBets> = ({
  __component,
  id,
  popularBet,
  popularBets,
  width,
  align,
  tokens
}) => {
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      console.log('CMSAltenarPopularBets -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WPopularBets',
        props: {
          iconOverride: {},
          variants: {
            PopularBet: Number(popularBet),
            PopularBets: Number(popularBets)
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('CMSAltenarPopularBets -> onOddSelect:', odd);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [popularBet, popularBets, tokens]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-popular-bets"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
