import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarLiveOddsDetails
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarLiveOddsDetails: FC<
  CMSComponentAltenarLiveOddsDetails
> = ({
  __component,
  id,
  categoryIds,
  championshipIds,
  goalBannerImage,
  headerCountDesktop,
  headerCountTablet,
  imageType,
  productName,
  showBets,
  showCorrectScoreModes,
  showEventCode,
  showEventDetailsMarketSearch,
  showEventDetailsNote,
  showFavouriteChampsIndicator,
  showFavouriteEventsIndicator,
  showHeaderMarkets,
  showLiveIndicator,
  showMoreMarketsButton,
  showScorePreview,
  showSpecialOddValue,
  eventList,
  eventBox,
  eventBoxCompetitors,
  headerMarkets,
  oddBox,
  liveIndicator,
  oddChangeIndicator,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      console.log('CMSAltenarLiveOddsDetails -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WLiveMatchOddsWithDetails',
        props: {
          championshipIds: championshipIds.map((item) => item.value),
          categoryIds: categoryIds.map((item) => item.value),
          headerCountDesktop: headerCountDesktop,
          headerCountTablet: headerCountTablet,
          imageType: Number(imageType),
          goalBannerImage: goalBannerImage ?? '',
          productName: productName ?? '',
          showBets,
          showCorrectScoreModes,
          showEventCode,
          showEventDetailsMarketSearch,
          showEventDetailsNote,
          showFavouriteChampsIndicator,
          showFavouriteEventsIndicator,
          showHeaderMarkets,
          showLiveIndicator,
          showMoreMarketsButton,
          showScorePreview,
          showSpecialOddValue,
          variants: {
            EventBoxCompetitors: Number(eventBoxCompetitors),
            EventBox: Number(eventBox),
            OddChangeIndicator: Number(oddChangeIndicator),
            OddBox: Number(oddBox),
            EventList: Number(eventList),
            HeaderMarkets: Number(headerMarkets),
            LiveIndicator: Number(liveIndicator)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WLiveMatchOddsWithDetails -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WLiveMatchOddsWithDetails -> onOddSelect:', odd);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      categoryIds,
      championshipIds,
      goalBannerImage,
      productName,
      headerCountDesktop,
      headerCountTablet,
      imageType,
      showBets,
      showCorrectScoreModes,
      showEventCode,
      showEventDetailsMarketSearch,
      showEventDetailsNote,
      showFavouriteChampsIndicator,
      showFavouriteEventsIndicator,
      showHeaderMarkets,
      showLiveIndicator,
      showMoreMarketsButton,
      showScorePreview,
      showSpecialOddValue,
      eventList,
      eventBox,
      eventBoxCompetitors,
      headerMarkets,
      oddBox,
      liveIndicator,
      oddChangeIndicator,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-live-odds-details"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
