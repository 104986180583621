import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarOverviewDetails
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarOverviewDetails: FC<
  CMSComponentAltenarOverviewDetails
> = ({
  __component,
  id,
  categoryIds,
  championshipIds,
  eventType,
  headerCountDesktop,
  headerCountTablet,
  imageType,
  openChampionshipsCount,
  openDatesCount,
  outrightGroupId,
  productName,
  showBets,
  showBookedLiveIndicator,
  showCorrectScoreModes,
  showEventCode,
  showEventDetailsMarketSearch,
  showFavouriteChampsIndicator,
  showFavouriteEventsIndicator,
  showHeaderMarkets,
  showInPopup,
  showMoreMarketsButton,
  showOutrightGroups,
  showPrintOdds,
  showSelectionCount,
  showSpecialOddValue,
  eventList,
  eventBox,
  eventBoxCompetitors,
  headerMarkets,
  oddBox,
  oddChangeIndicator,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      console.log('CMSAltenarOverviewDetails -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WLiveOverviewWithDetails',
        props: {
          categoryIds: categoryIds?.map((item) => item.value),
          championshipIds: championshipIds?.map((item) => item.value),
          eventType,
          headerCountDesktop: headerCountDesktop,
          headerCountTablet: headerCountTablet,
          imageType: Number(imageType),
          openChampionshipsCount,
          openDatesCount,
          outrightGroupId,
          productName: productName || '',
          showBets,
          showBookedLiveIndicator,
          showCorrectScoreModes,
          showEventCode,
          showEventDetailsMarketSearch,
          showFavouriteChampsIndicator,
          showFavouriteEventsIndicator,
          showHeaderMarkets,
          showInPopup: showInPopup?.map((item) => item.value),
          showMoreMarketsButton,
          showOutrightGroups,
          showPrintOdds,
          showSelectionCount,
          showSpecialOddValue,
          variants: {
            EventList: Number(eventList),
            EventBox: Number(eventBox),
            EventBoxCompetitors: Number(eventBoxCompetitors),
            OddBox: Number(oddBox),
            OddChangeIndicator: Number(oddChangeIndicator),
            HeaderMarkets: Number(headerMarkets)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WLiveOverviewWithDetails -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onNonValidId: (args: {
            page?: string;
            widget?: string;
            sportId?: number;
            eventId?: number;
            horseRaceId?: number;
            categoryIds?: Array<number>;
            championshipIds?: Array<number>;
          }) => {
            // console.log('WLiveOverviewWithDetails -> onNonValidId:', args);
            // hide when non valid id
            altenarContainerRef.current?.classList.add('hidden');
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WLiveOverviewWithDetails -> onOddSelect:', odd);
          },
          onPrintOddsClick: () => {
            // console.log('WLiveOverviewWithDetails -> onPrintOddsClick:');
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      categoryIds,
      championshipIds,
      eventType,
      headerCountDesktop,
      headerCountTablet,
      imageType,
      openChampionshipsCount,
      openDatesCount,
      outrightGroupId,
      productName,
      showBets,
      showBookedLiveIndicator,
      showCorrectScoreModes,
      showEventCode,
      showEventDetailsMarketSearch,
      showFavouriteChampsIndicator,
      showFavouriteEventsIndicator,
      showHeaderMarkets,
      showInPopup,
      showMoreMarketsButton,
      showOutrightGroups,
      showPrintOdds,
      showSelectionCount,
      showSpecialOddValue,
      eventList,
      eventBox,
      eventBoxCompetitors,
      headerMarkets,
      oddBox,
      oddChangeIndicator,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-overview-details"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
