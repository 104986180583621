import { CMSComponent, CMSComponentRef } from '@/types/api/ge-strapi/cms-page';

export function isContainingTypeFormComponent(data?: CMSComponent[]) {
  return (
    data?.find(
      (item: CMSComponent) => item.__component === CMSComponentRef.FORM
    ) !== undefined
  );
}

export function isContainingPredictionGameComponent(data?: CMSComponent[]) {
  return (
    data?.find(
      (item: CMSComponent) =>
        item.__component === CMSComponentRef.PREDICTION_GAME
    ) !== undefined
  );
}

export function isContainingAltenarComponent(data?: CMSComponent[]) {
  return (
    data?.find(
      (item: { __component: string }) =>
        item.__component === CMSComponentRef.ALTENAR_EVENT_CARD ||
        item.__component === CMSComponentRef.ALTENAR_EVENT_DETAILS ||
        item.__component === CMSComponentRef.ALTENAR_EVENTS_CHAMPIONSHIPS ||
        item.__component === CMSComponentRef.ALTENAR_OUTRIGHT_EVENTS ||
        item.__component === CMSComponentRef.ALTENAR_OUTRIGHTS_CHAMPIONSHIPS ||
        item.__component === CMSComponentRef.ALTENAR_LIVE_ODDS ||
        item.__component === CMSComponentRef.ALTENAR_LIVE_ODDS_DETAILS ||
        item.__component === CMSComponentRef.ALTENAR_HIGHLIGHTS ||
        item.__component === CMSComponentRef.ALTENAR_OVERVIEW_DETAILS ||
        item.__component === CMSComponentRef.ALTENAR_POPULAR_BETS ||
        item.__component === CMSComponentRef.ALTENAR_SCORE_BOARD ||
        item.__component === CMSComponentRef.ALTENAR_UPCOMING ||
        item.__component === CMSComponentRef.ALTENAR_BOOSTED_ODDS
    ) !== undefined
  );
}
