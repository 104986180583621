import { FC, useEffect, useRef } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { CMSComponentScript } from '@/types/api/ge-strapi/cms-page/script';
import Script from 'next/script';

const CMSCallCMSComponentScript: FC<CMSComponentScript> = ({
  id,
  src,
  srcScriptAtBottom,
  content,
  contentScriptAtBottom,
  align,
  width
}) => {
  const boxContentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!contentScriptAtBottom && boxContentRef.current && content) {
      addScriptToElement({
        element: boxContentRef.current,
        type: 'content',
        id,
        value: content,
        getId: getScriptId
      });
    }
  }, [boxContentRef, id, content, contentScriptAtBottom]);

  useEffect(() => {
    if (!srcScriptAtBottom && boxContentRef.current && src) {
      addScriptToElement({
        element: boxContentRef.current,
        type: 'src',
        id,
        value: src,
        getId: getScriptId
      });
    }
  }, [boxContentRef, id, src, srcScriptAtBottom]);

  if (content === undefined && src === undefined) {
    return null;
  }

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-script-container"
    >
      <Box
        width={toArrayProps(width, '%')}
        data-testid="cms-script-content"
        ref={boxContentRef}
      >
        {contentScriptAtBottom && content !== undefined ? (
          <Script
            key={`cms-script-${id}-content`}
            id={`cms-script-${id}-content`}
            data-testid={`cms-script-${id}-content`}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        ) : null}
        {srcScriptAtBottom && src !== undefined ? (
          <Script
            key={`cms-script-${id}-src`}
            id={`cms-script-${id}-src`}
            data-testid={`cms-script-${id}-src`}
            src={src}
          ></Script>
        ) : null}
      </Box>
    </HStack>
  );
};

export default CMSCallCMSComponentScript;

function addScriptToElement({
  element,
  id,
  type,
  value,
  getId
}: {
  element: HTMLDivElement;
  type: 'src' | 'content';
  id: number;
  value: string;
  getId: (id: number, type: string) => string;
}) {
  const fullId = getId(id, type as string);
  const script = element.querySelector(`#${fullId}`);
  if (script === null) {
    const script = document.createElement('script');
    script.id = fullId;
    script.dataset.testid = fullId;
    if (type === 'src') {
      script.src = value;
    } else {
      script.innerHTML = value;
    }
    element.appendChild(script);
  }
}

const getScriptId = (id: number, type: string) => `cms-script-${id}-${type}`;
