import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarBoostedOdds
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarBoostedOdds: FC<CMSComponentAltenarBoostedOdds> = ({
  __component,
  id,
  scrollerControls,
  slideWidth,
  sportId,
  stepSize,
  oddChangeIndicator,
  snapTo,
  liveIndicator,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarBoostedOdds -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WBoostedOdds',
        props: {
          sportId: Number(sportId),
          slideWidth: Number(slideWidth),
          snapTo,
          stepSize: Number(stepSize),
          scrollerControls: Number(scrollerControls),
          variants: {
            OddChangeIndicator: Number(oddChangeIndicator),
            LiveIndicator: Number(liveIndicator)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WLiveMatchOdds -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WLiveMatchOdds -> onOddSelect:', odd);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      sportId,
      slideWidth,
      snapTo,
      stepSize,
      scrollerControls,
      oddChangeIndicator,
      liveIndicator,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-boosted-odds"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
