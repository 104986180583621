import { FC } from 'react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { HStack, SimpleGrid, useTheme } from '@chakra-ui/react';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { proseBaseStyles } from '@/theme/base';
import { CMSComponentGrid } from '@/types/api/ge-strapi/cms-page/grid';

const CMSCallCMSComponentGrid: FC<CMSComponentGrid> = ({
  children,
  config,
  width,
  align
}) => {
  const theme = useTheme();
  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-grid-container"
    >
      <SimpleGrid
        data-testid="cms-grid-content"
        width={toArrayProps(width, '%')}
        justifyContent={align ? align.value : 'center'}
        columns={toArrayProps(config) as number[]}
        spacing={10}
      >
        {children.map((block, index) => (
          <Prose
            data-testid={`cms-grid-child`}
            key={index}
            dangerouslySetInnerHTML={{ __html: block.content }}
            __css={{
              ...proseBaseStyles,
              'ul > li::marker': {
                color: theme.colors.buttonPrimary
              }
            }}
          />
        ))}
      </SimpleGrid>
    </HStack>
  );
};

export default CMSCallCMSComponentGrid;
