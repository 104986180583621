import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarOutrightEvents
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarOutrightEvents: FC<
  CMSComponentAltenarOutrightEvents
> = ({
  __component,
  id,
  categoryIds,
  championshipIds,
  minOutrightSelectionCount,
  outrightGroupId,
  showOutrightGroups,
  eventList,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarOutrightEvents -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WOutrightEvents',
        props: {
          championshipIds: championshipIds.map((item) => item.value),
          categoryIds: categoryIds.map((item) => item.value),
          minOutrightSelectionCount: minOutrightSelectionCount ?? undefined,
          outrightGroupId: outrightGroupId ?? undefined,
          showOutrightGroups,
          variants: {
            EventList: Number(eventList)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WOutrightEvents -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WOutrightEvents -> onOddSelect:', odd);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      categoryIds,
      championshipIds,
      minOutrightSelectionCount,
      outrightGroupId,
      showOutrightGroups,
      eventList,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-outright-events"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
