import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarHighlights
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent, navigateToSport } from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarHighlights: FC<CMSComponentAltenarHighlights> = ({
  __component,
  id,
  eventCount,
  headerCountDesktop,
  headerCountTablet,
  imageType,
  scrollerControls,
  showBets,
  showBookedLiveIndicator,
  showCorrectScoreModes,
  showEventCode,
  showEventDetails,
  showEventDetailsMarketSearch,
  showFavouriteEventsIndicator,
  showHeaderIcon,
  showHeaderMarkets,
  showInPopup,
  showMoreButton,
  showMoreMarketsButton,
  showSelectionCount,
  showSpecialOddValue,
  eventList,
  eventBox,
  eventBoxCompetitors,
  headerMarkets,
  oddBox,
  oddChangeIndicator,
  sportTabs,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarHighlights -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WHighlights',
        props: {
          eventCount,
          headerCountDesktop: headerCountDesktop,
          headerCountTablet: headerCountTablet,
          imageType: Number(imageType),
          scrollerControls: Number(scrollerControls),
          showBets,
          showBookedLiveIndicator,
          showCorrectScoreModes,
          showEventCode,
          showEventDetails,
          showEventDetailsMarketSearch,
          showFavouriteEventsIndicator,
          showHeaderIcon,
          showHeaderMarkets,
          showInPopup: showInPopup.map((item) => item.value),
          showMoreButton,
          showMoreMarketsButton,
          showSelectionCount,
          showSpecialOddValue,
          variants: {
            EventBoxCompetitors: Number(eventBoxCompetitors),
            EventBox: Number(eventBox),
            OddChangeIndicator: Number(oddChangeIndicator),
            OddBox: Number(oddBox),
            EventList: Number(eventList),
            HeaderMarkets: Number(headerMarkets),
            SportTabs: Number(sportTabs)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WHighlights -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WHighlights -> onOddSelect:', odd);
          },
          onShowMoreSportEventsClick: (type: string, sportId: number) => {
            // console.log('WHighlights -> onShowMoreSportEventsClick', {
            //   type,
            //   sportId
            // });

            navigateToSport(locale, sportId);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      eventCount,
      headerCountDesktop,
      headerCountTablet,
      imageType,
      scrollerControls,
      showBets,
      showBookedLiveIndicator,
      showCorrectScoreModes,
      showEventCode,
      showEventDetails,
      showEventDetailsMarketSearch,
      showFavouriteEventsIndicator,
      showHeaderIcon,
      showHeaderMarkets,
      showInPopup,
      showMoreButton,
      showMoreMarketsButton,
      showSelectionCount,
      showSpecialOddValue,
      eventList,
      eventBox,
      eventBoxCompetitors,
      headerMarkets,
      oddBox,
      oddChangeIndicator,
      sportTabs,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-highlights"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
