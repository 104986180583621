import { FC } from 'react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { HStack, useTheme } from '@chakra-ui/react';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { proseBaseStyles } from '@/theme/base';
import { CMSComponentTextContent } from '@/types/api/ge-strapi/cms-page/textContent';

const CMSCallCMSComponentTextContent: FC<CMSComponentTextContent> = ({
  content,
  align,
  width
}) => {
  const theme = useTheme();
  return (
    <HStack
      data-testid="cms-text-content-container"
      justifyContent={align ? align.value : 'center'}
    >
      <Prose
        data-testid="cms-text-content-content"
        width={toArrayProps(width, '%')}
        dangerouslySetInnerHTML={{ __html: content }}
        __css={{
          ...proseBaseStyles,
          'ul > li::marker': {
            color: theme.colors.buttonPrimary
          }
        }}
      />
    </HStack>
  );
};

export default CMSCallCMSComponentTextContent;
