import { FC } from 'react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { proseBaseStyles } from '@/theme/base';
import { HStack, Heading, HeadingProps } from '@chakra-ui/react';
import { CMSComponentTitle } from '@/types/api/ge-strapi/cms-page/title';

const CMSCallCMSComponentTitle: FC<CMSComponentTitle> = ({
  text,
  type,
  align,
  width,
  textAlign
}) => {
  const typeToProps = (type: string): HeadingProps => {
    const config: { [key in string]: HeadingProps } = {
      H1: {
        as: 'h1',
        fontSize: proseBaseStyles.h1.fontSize,
        lineHeight: proseBaseStyles.h1.lineHeight
      },
      H2: {
        as: 'h2',
        fontSize: proseBaseStyles.h2.fontSize,
        lineHeight: proseBaseStyles.h2.lineHeight
      },
      H3: {
        as: 'h3',
        fontSize: proseBaseStyles.h3.fontSize,
        lineHeight: proseBaseStyles.h3.lineHeight
      },
      H4: {
        as: 'h4',
        fontSize: proseBaseStyles.h4.fontSize,
        lineHeight: proseBaseStyles.h4.lineHeight
      },
      H5: { as: 'h5' },
      H6: { as: 'h6', size: 'xs' }
    };
    return config[type] || {};
  };

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-title-container"
    >
      <Heading
        {...typeToProps(type)}
        width={toArrayProps(width, '%')}
        textAlign={textAlign?.value}
        data-testid="cms-title-content"
      >
        {text}
      </Heading>
    </HStack>
  );
};

export default CMSCallCMSComponentTitle;
