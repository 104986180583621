import { FC, MutableRefObject, useCallback } from 'react';
import {
  AltenarEventOdd,
  CMSComponentAltenarEventDetails
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarEventDetails: FC<CMSComponentAltenarEventDetails> = ({
  __component,
  id,
  eventId,
  isCombinationsPrintingEnabled,
  isPrintingEnabled,
  openMarketsCount,
  marketGroupId,
  showBets,
  showCashOutWarning,
  showCorrectScoreModes,
  showEventDetailsMarketSearch,
  showEventDetailsNote,
  showLiveIndicator,
  oddChangeIndicator,
  width,
  align,
  tokens
}) => {
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarEventDetails -> addWidget');
      if (altenarContainerRef.current === null) return;

      if (Number.isNaN(Number(eventId))) return;

      window.altenarWSDK.addWidget({
        widget: 'WEventDetailsMarkets',
        props: {
          eventId: Number(eventId),
          openMarketsCount: openMarketsCount ? openMarketsCount : undefined,
          marketGroupId: marketGroupId ? marketGroupId : undefined,
          isCombinationsPrintingEnabled,
          isPrintingEnabled,
          showBets,
          showCashOutWarning,
          showCorrectScoreModes,
          showEventDetailsMarketSearch,
          showEventDetailsNote,
          showLiveIndicator,
          variants: {
            OddChangeIndicator: Number(oddChangeIndicator)
          },
          onNonValidId: (args: {
            page?: string;
            widget?: string;
            sportId?: number;
            eventId?: number;
            horseRaceId?: number;
            categoryIds?: number[];
            championshipIds?: number[];
          }) => {
            // console.log('WEventDetailsMarkets -> onNonValidId:', args);
            // hide when non valid id
            altenarContainerRef.current?.classList.add('hidden');
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WEventDetailsMarkets -> onOddSelect:', odd);
          },
          onResultsButtonClick: (openMarketCount: number) => {
            // console.log('WEventDetailsMarkets -> onResultsButtonClick:', openMarketCount);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      eventId,
      openMarketsCount,
      marketGroupId,
      isCombinationsPrintingEnabled,
      isPrintingEnabled,
      showBets,
      showCashOutWarning,
      showCorrectScoreModes,
      showEventDetailsMarketSearch,
      showEventDetailsNote,
      showLiveIndicator,
      oddChangeIndicator,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-event-details"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
