import { FC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  HStack,
  Heading
} from '@chakra-ui/react';
import { CMSComponentAccordion } from '@/types/api/ge-strapi/cms-page/accordion';
import { Prose } from '@nikolovlazar/chakra-ui-prose';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { proseBaseStyles } from '@/theme/base';

const CMSAccordion: FC<CMSComponentAccordion> = ({
  children,
  align,
  width
}) => {
  if (!children.length) return null;

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-accordion-wrapper"
    >
      <Accordion
        data-testid="cms-accordion"
        width={toArrayProps(width, '%')}
        allowMultiple
        padding={['2rem 1.5rem', '2rem 1.5rem', '2rem 1.5rem']}
        borderColor={'white.200'}
        borderStyle={'solid'}
        borderWidth={'0.5px'}
        borderRadius={'1.25rem'}
      >
        {children.map((item, index) => (
          <AccordionItem
            key={item.id}
            _first={{ borderTop: 'none' }}
            _last={{ borderBottom: 'none' }}
            data-testid={'cms-accordion-item'}
          >
            <Heading as="h4">
              <AccordionButton
                style={{ padding: '1.25rem 0.5rem' }}
                _hover={{ background: 'none' }}
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontSize={['1.125rem', '1.125rem', '1.5rem']}
                  fontWeight={500}
                  lineHeight={['1.5rem', '1.5rem', '1.875rem']}
                >
                  {item.title}
                </Box>
                <AccordionIcon color="buttonTextSecondary" />
              </AccordionButton>
            </Heading>
            <Prose __css={{ ...proseBaseStyles }}>
              <AccordionPanel
                style={{
                  paddingInline: '.5rem',
                  paddingBlock: '2rem',
                  paddingBlockEnd: index === children.length - 1 ? 0 : undefined
                }}
                dangerouslySetInnerHTML={{ __html: item.content }}
                fontSize={['0.875rem', '0.875rem', '1rem']}
                lineHeight={['1.25rem', '1.25rem', '1.625rem']}
                css={{
                  'h1, h2, h3, h4, h5': {
                    fontWeight: '500',
                    fontSize: '1rem !important',
                    lineHeight: '1.25rem !important'
                  },
                  '@media screen and (min-width: 48rem)': {
                    'h1, h2, h3, h4, h5': {
                      fontSize: '1.125rem !important',
                      lineHeight: 'normal !important'
                    }
                  }
                }}
              />
            </Prose>
          </AccordionItem>
        ))}
      </Accordion>
    </HStack>
  );
};

export default CMSAccordion;
