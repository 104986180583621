import { Box, HStack } from '@chakra-ui/react';
import { MutableRefObject } from 'react';
import { NumberPerScreen, SelectedAlign } from '@/types/api/ge-strapi/cms-page';
import { toArrayProps } from '@/components/CMS/CMSComponents';

export function CMSAltenarBaseWidget({
  altenarContainerRef,
  name,
  align,
  width
}: {
  altenarContainerRef: MutableRefObject<HTMLDivElement | null>;
  name: string;
  align?: SelectedAlign;
  width?: NumberPerScreen;
}) {
  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid={`cms-${name}-container`}
    >
      <Box
        data-testid={`cms-${name}-content`}
        width={toArrayProps(width, '%')}
        ref={altenarContainerRef}
      />
    </HStack>
  );
}
