import { FC } from 'react';
import { CMSComponentImage } from '@/types/api/ge-strapi/cms-page/image';
import { HStack, Box } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { breakpoints } from '@/theme/base';
import { strapiImageUrl } from '@/helpers/url';

const CMSCallCMSComponentImage: FC<CMSComponentImage> = ({
  config,
  alt,
  title,
  width,
  align,
  textAlign
}) => {
  const smUrl = strapiImageUrl(config.sm.data.attributes.url);

  return (
    <HStack
      data-testid="cms-image-container"
      justifyContent={align ? align.value : 'center'}
    >
      <Box
        as="picture"
        width={toArrayProps(width, '%')}
        data-testid="cms-image-picture"
      >
        <source
          media={`(max-width: calc(${breakpoints.xs} - 1px) )`}
          srcSet={smUrl}
        />
        <source
          media={`(min-width: ${breakpoints.xs}) and (max-width:${breakpoints.sm})`}
          srcSet={strapiImageUrl(config.md.data.attributes.url)}
        />
        <source
          media={`(min-width: calc(${breakpoints.sm} + 1px)`}
          srcSet={strapiImageUrl(config.lg.data.attributes.url)}
        />
        <img
          data-testid="cms-image-img"
          alt={alt || title || ''}
          src={strapiImageUrl(config.sm.data.attributes.url)}
          width={config.sm.data.attributes.width}
          height={config.sm.data.attributes.height}
          style={{ width: '100%', maxWidth: '100%' }}
        />
        {title ? (
          <Box
            as="figcaption"
            data-testid="cms-image-caption"
            textAlign={textAlign?.value}
          >
            {title}
          </Box>
        ) : null}
      </Box>
    </HStack>
  );
};

export default CMSCallCMSComponentImage;
