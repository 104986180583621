import { FC, MutableRefObject, useCallback } from 'react';
import { CMSComponentAltenarScoreBoard } from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarScoreBoard: FC<CMSComponentAltenarScoreBoard> = ({
  __component,
  id,
  eventId,
  productName,
  width,
  align,
  tokens
}) => {
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarScoreBoard -> addWidget');
      if (altenarContainerRef.current === null) return;

      if (Number.isNaN(Number(eventId))) return;

      window.altenarWSDK.addWidget({
        widget: 'WScoreboard',
        props: {
          eventId: Number(eventId),
          productName: productName ?? ''
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [eventId, productName, tokens]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-score-board"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
