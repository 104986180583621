import { FC } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { CMSComponentForm } from '@/types/api/ge-strapi/cms-page/form';

const CMSCallCMSComponentForm: FC<CMSComponentForm> = ({
  align,
  width,
  typeFormId
}) => {
  // console.log('Form -> CMSCallCMSComponentForm', {
  //   align,
  //   width,
  //   typeFormId
  // });

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      my={[7, 14]} // need to be 12 / 24 -> but 5 / 10 in flex gap of the parent
      data-testid="cms-form-container"
    >
      <Box
        width={toArrayProps(width, '%')}
        data-testid="cms-form-content"
        data-tf-live={typeFormId}
      />
    </HStack>
  );
};

export default CMSCallCMSComponentForm;
