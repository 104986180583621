import { FC } from 'react';
import { Divider, HStack } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { CMSComponentDivider } from '@/types/api/ge-strapi/cms-page/divider';

const CMSCallCMSComponentDivider: FC<CMSComponentDivider> = ({
  align,
  width
}) => {
  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      my={[7, 14]} // need to be 12 / 24 -> but 5 / 10 in flex gap of the parent
      data-testid="cms-divider-container"
    >
      <Divider
        bgColor="whiteAlpha.200"
        width={toArrayProps(width, '%')}
        data-testid="cms-divider-content"
      />
    </HStack>
  );
};

export default CMSCallCMSComponentDivider;
