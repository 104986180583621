import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEventShowMoreChampionshipEvents,
  AltenarEvent,
  CMSComponentAltenarOutrightsChampionships
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import {
  navigateToAltenarEvent,
  navigateToChampionship
} from '@/helpers/altenar';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';

export const CMSAltenarOutrightsChampionships: FC<
  CMSComponentAltenarOutrightsChampionships
> = ({
  __component,
  id,
  championshipIds,
  eventCount,
  headerType,
  showHeaderIcon,
  showMoreButton,
  showOutrightGroups,
  eventList,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarOutrightsChampionships -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WOutrightsByChampionships',
        props: {
          championshipIds: championshipIds.map((item) => item.value),
          eventCount,
          headerType: Number(headerType),
          showHeaderIcon,
          showMoreButton,
          showOutrightGroups,
          variants: {
            EventList: Number(eventList)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WOutrightsByChampionships -> onEventSelect:', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WOutrightsByChampionships -> onOddSelect:', odd);
          },
          onShowMoreChampionshipEventsClick: (
            event: AltenarEventShowMoreChampionshipEvents
          ) => {
            // console.log(
            //   'WOutrightsByChampionships -> onShowMoreChampionshipEventsClick:',
            //   event
            // );
            navigateToChampionship(locale, event);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      championshipIds,
      eventCount,
      headerType,
      showHeaderIcon,
      showMoreButton,
      showOutrightGroups,
      eventList,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );
  return (
    <CMSAltenarBaseWidget
      name="altenar-outrights-championships"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
