import {
  CMSDataStoreContext,
  toArrayProps
} from '@/components/CMS/CMSComponents';
import { FC, useContext, useMemo, useState } from 'react';
import { Box, Heading, HStack } from '@chakra-ui/react';
import GameImageAndTitle, {
  GameImageAndTitleVariants
} from '@/components/GamesCatalog/GameImageAndTitle';
import { CMSComponentGameCatalog } from '@/types/api/ge-strapi/cms-page/gameCatalog';
import { MotionButton } from '@/components/Motion';
import { toRem } from '@/helpers/toRem';
import { useRouter } from 'next/router';

const CMSCallCMSComponentGameCatalog: FC<CMSComponentGameCatalog> = ({
  title,
  link,
  align,
  width,
  children
}) => {
  const context = useContext(CMSDataStoreContext);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  return (
    <HStack
      data-testid="cms-game-catalog-container"
      justifyContent={align ? align.value : 'center'}
      flexWrap={'wrap'}
    >
      <Box
        data-testid="cms-game-catalog-background"
        width={toArrayProps(width, '%')}
        bgColor={['transparent', 'customBlackBackground.300']}
        borderRadius={toRem(40)}
        padding={[0, 8]}
      >
        {title || link ? (
          <HStack mb={6}>
            {title ? (
              <Heading
                data-testid="cms-game-catalag-title"
                as="h3"
                fontSize={[toRem(15), toRem(18)]}
                lineHeight={[toRem(18), toRem(22)]}
              >
                {title}
              </Heading>
            ) : null}

            {link ? (
              <MotionButton
                data-testid="cms-game-catalag-see-all-btn"
                variant={'outline'}
                borderColor={'whiteAlpha.200'}
                h={toRem(30)}
                px={toRem(18) + '!'}
                fz={toRem(15)}
                lh={toRem(18)}
                fontWeight={'500'}
                ml={'auto!'}
                onClick={() => {
                  if (link.target === '_blank') {
                    window.open(link.url, link.target);
                  } else {
                    setIsLoading(true);
                    router.push(link.url);
                  }
                }}
                isLoading={isLoading}
                disabled={isLoading}
                title={link?.title || link.text}
              >
                {link.text}
              </MotionButton>
            ) : null}
          </HStack>
        ) : null}
        <Box
          data-testid="cms-game-catalog-games"
          display={'grid'}
          gap={[6, '120px 30px']}
          gridTemplateColumns={[
            '1fr 1fr',
            '1fr 1fr',
            '1fr 1fr 1fr',
            '1fr 1fr 1fr',
            '1fr 1fr 1fr'
          ]}
          minHeight="0"
          minWidth="0"
          __css={{
            '> *': {
              // remove margin from game - let the grid gap handle it
              mb: 0,
              mt: 0
            }
          }}
        >
          {useMemo(
            () =>
              children?.map((selectedGame) => {
                const game = context.games[selectedGame.uuid];
                const providerName =
                  typeof game?.provider === 'string'
                    ? context.providers[game.provider]?.name
                    : undefined;
                return game ? (
                  <GameImageAndTitle
                    minWidth="0"
                    overflow={'hidden'}
                    variant={GameImageAndTitleVariants.CMS}
                    game={game}
                    providerName={providerName}
                    key={game?.id}
                  />
                ) : null;
              }),
            [children, context.games, context.providers]
          )}
        </Box>
      </Box>
    </HStack>
  );
};

export default CMSCallCMSComponentGameCatalog;
