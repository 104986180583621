import React, { FC, useContext, useRef } from 'react';
import { Box, HStack, useMediaQuery } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import { CMSComponentPredictionGame } from '@/types/api/ge-strapi/cms-page/predictionGame';
import Script from 'next/script';
import { SmarticoContext } from '@/components/Smartico/SmarticoScript';

const CMSPredictionGame: FC<CMSComponentPredictionGame> = ({
  id,
  matchXId,
  theme,
  height,
  align,
  width
}) => {
  const uniqueId = `script-match-x-${matchXId}-${id}`;
  const boxContentRef = useRef<HTMLDivElement | null>(null);
  const [isScreenMobile] = useMediaQuery('(max-width: 600px)');
  const { isSmarticoInitialized } = useContext(SmarticoContext);

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-prediction-game-container"
    >
      <Box
        width={toArrayProps(width, '%')}
        data-testid="cms-prediction-game-content"
        ref={boxContentRef}
      >
        <Box
          id={uniqueId}
          as="iframe"
          data-testid={uniqueId}
          style={{
            width: '100%',
            minHeight: isScreenMobile
              ? '100vh'
              : `${height ? height + 'px' : '100vh'}`
          }}
        ></Box>

        {isSmarticoInitialized ? (
          <Script id={uniqueId} strategy={'lazyOnload'}>{`
              window?._smartico?.miniGame(${matchXId}, {
                  iframe: '${uniqueId}',
                  zoom: 1,
                  height: 'auto',
                  theme: '${theme}'
                });
          `}</Script>
        ) : null}
      </Box>
    </HStack>
  );
};

export default CMSPredictionGame;
