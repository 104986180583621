import { FC, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { MotionButton } from '@/components/Motion';
import { toRem } from '@/helpers/toRem';
import { useRouter } from 'next/router';
import { CMSComponentCallToAction } from '@/types/api/ge-strapi/cms-page/callToAction';
import { toArrayProps } from '@/components/CMS/CMSComponents';

const CMSCallCMSComponentCallToAction: FC<CMSComponentCallToAction> = ({
  link,
  align,
  variant,
  width,
  textAlign
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  return (
    <HStack
      justifyContent={align ? align.value : 'center'}
      data-testid="cms-cta-container"
    >
      <Box
        data-testid="cms-cta-content"
        width={toArrayProps(width, '%')}
        textAlign={textAlign?.value}
      >
        <MotionButton
          data-testid="cms-cta-button"
          variant={variant === 'secondary' ? 'outline' : 'solid'}
          background={variant === 'secondary' ? '' : 'buttonPrimary'}
          onClick={() => {
            if (link.target === '_blank') {
              window.open(link.url, link.target);
            } else {
              setIsLoading(true);
              router.push(link.url);
            }
          }}
          isLoading={isLoading}
          disabled={isLoading}
          fontSize={toRem(15)}
          fontWeight="500"
          lineHeight="1"
          padding={`${toRem(7.5)} ${toRem(27)}`}
          title={link?.title || link.text}
        >
          {link.text}
        </MotionButton>
      </Box>
    </HStack>
  );
};

export default CMSCallCMSComponentCallToAction;
