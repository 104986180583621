import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import {
  AltenarEventOdd,
  AltenarEvent,
  CMSComponentAltenarUpcoming
} from 'types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { CMSAltenarBaseWidget } from './AltenarBaseWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';

export const CMSAltenarUpcoming: FC<CMSComponentAltenarUpcoming> = ({
  __component,
  id,
  eventCount,
  headerCountDesktop,
  headerCountTablet,
  imageType,
  scrollerControls,
  showBets,
  showBookedLiveIndicator,
  showCorrectScoreModes,
  showEventCode,
  showEventDetails,
  showEventDetailsMarketSearch,
  showFavouriteEventsIndicator,
  showHeaderIcon,
  showHeaderMarkets,
  showInPopup,
  showMoreMarketsButton,
  showSelectionCount,
  showSpecialOddValue,
  eventList,
  eventBox,
  eventBoxCompetitors,
  headerMarkets,
  oddBox,
  oddChangeIndicator,
  sportTabs,
  width,
  align,
  tokens
}) => {
  const { locale } = useRouter();
  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      console.log('CMSAltenarUpcoming -> addWidget');
      if (altenarContainerRef.current === null) return;

      window.altenarWSDK.addWidget({
        widget: 'WUpcoming',
        props: {
          eventCount,
          headerCountDesktop,
          headerCountTablet,
          imageType: Number(imageType),
          scrollerControls: Number(scrollerControls),
          showBets,
          showBookedLiveIndicator,
          showCorrectScoreModes,
          showEventCode,
          showEventDetails,
          showEventDetailsMarketSearch,
          showFavouriteEventsIndicator,
          showHeaderIcon,
          showHeaderMarkets,
          showInPopup: showInPopup.map((item) => item.value),
          showMoreMarketsButton,
          showSelectionCount,
          showSpecialOddValue,
          variants: {
            EventList: Number(eventList),
            EventBox: Number(eventBox),
            EventBoxCompetitors: Number(eventBoxCompetitors),
            HeaderMarkets: Number(headerMarkets),
            OddBox: Number(oddBox),
            OddChangeIndicator: Number(oddChangeIndicator),
            SportTabs: Number(sportTabs)
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('CMSAltenarUpcoming -> onEventSelect', { event });
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('CMSAltenarUpcoming -> onOddSelect', { odd });
          },
          onShowMoreSportEventsClick: (type: any, sportId: number) => {
            // console.log('CMSAltenarUpcoming -> onShowMoreSportEventsClick', {
            //   type,
            //   sportId
            // });
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      eventCount,
      headerCountDesktop,
      headerCountTablet,
      imageType,
      scrollerControls,
      showBets,
      showBookedLiveIndicator,
      showCorrectScoreModes,
      showEventCode,
      showEventDetails,
      showEventDetailsMarketSearch,
      showFavouriteEventsIndicator,
      showHeaderIcon,
      showHeaderMarkets,
      showInPopup,
      showMoreMarketsButton,
      showSelectionCount,
      showSpecialOddValue,
      eventList,
      eventBox,
      eventBoxCompetitors,
      headerMarkets,
      oddBox,
      oddChangeIndicator,
      sportTabs,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <CMSAltenarBaseWidget
      name="altenar-upcoming"
      altenarContainerRef={altenarContainerRef}
      width={width}
      align={align}
    />
  );
};
