import { FC, MutableRefObject, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';
import { toArrayProps } from '@/components/CMS/CMSComponents';
import {
  CMSComponentAltenarEventCard,
  AltenarEventOdd,
  AltenarEvent
} from '@/types/api/ge-strapi/cms-page';
import { useAddWidget } from '@/hooks/useAltenarWidget';
import { navigateToAltenarEvent } from '@/helpers/altenar';

export const CMSAltenarEventCard: FC<CMSComponentAltenarEventCard> = ({
  __component,
  id,
  width,
  align,
  eventId,
  imageType,
  showBookedLiveIndicator,
  showLiveIndicator,
  showScorePreview,
  bannerEventBox,
  bannerEventBoxCompetitors,
  oddBox,
  oddChangeIndicator,
  tokens
}) => {
  const { locale } = useRouter();

  const addWidget = useCallback(
    (altenarContainerRef: MutableRefObject<HTMLDivElement | null>) => {
      // console.log('CMSAltenarEventCard -> addWidget');
      if (altenarContainerRef.current === null) return;

      if (Number.isNaN(Number(eventId))) return;

      window.altenarWSDK.addWidget({
        widget: 'WEventCard',

        props: {
          eventId: Number(eventId),
          imageType: Number(imageType),
          showBookedLiveIndicator,
          showLiveIndicator,
          showScorePreview,
          variants: {
            BannerEventBox: Number(bannerEventBox),
            BannerEventBoxCompetitors: Number(bannerEventBoxCompetitors),
            OddBox: Number(oddBox),
            OddChangeIndicator: Number(oddChangeIndicator)
          },
          onCompetitorLogoClick: (id: number) => {
            // console.log('WEventCard -> onCompetitorLogoClick:', id);
          },
          onEmptyData: () => {
            // console.log('WEventCard -> onEmptyData');
            // hide when empty
            altenarContainerRef.current?.classList.add('hidden');
          },
          onEventSelect: (event: AltenarEvent) => {
            // console.log('WEventCard -> onEventSelect', event);
            navigateToAltenarEvent(locale, event);
          },
          onOddSelect: (odd: AltenarEventOdd) => {
            // console.log('WEventCard -> onOddSelect:', odd);
          }
        },
        container: altenarContainerRef.current,
        tokens
      });
    },
    [
      locale,
      eventId,
      imageType,
      showBookedLiveIndicator,
      showLiveIndicator,
      showScorePreview,
      bannerEventBox,
      bannerEventBoxCompetitors,
      oddBox,
      oddChangeIndicator,
      tokens
    ]
  );
  const { altenarContainerRef } = useAddWidget(
    `${__component}-${id}`,
    addWidget
  );

  return (
    <Box
      data-testid="cms-altenar-event-card"
      width={toArrayProps(width, '%')}
      ref={altenarContainerRef}
      display={'flex'}
      justifyContent={align ? align.value : 'center'}
    />
  );
};
