import {
  CMSComponent,
  CMSComponentRef,
  CMSPageApiData,
  NumberPerScreen
} from '@/types/api/ge-strapi/cms-page';
import { DomainLicense } from '@/utils/multiDomains';
import { CMSDataStore } from '@/components/CMS/CMSPage.types';
import { Game } from '@/components/GamesCatalog/types';
import getConfig from 'next/config';
import React, { FC } from 'react';
import {
  CMSAccordion,
  CMSCallToAction,
  CMSDivider,
  CMSForm,
  CMSGrid,
  CMSImage,
  CMSPredictionGame,
  CMSScript,
  CMSTextContent,
  CMSTitle
} from '@/components/CMS/components';
import CMSGameCatalog from '@/components/CMS/components/GameCatalog';
import {
  CMSAltenarBestSlip,
  CMSAltenarBoostedOdds,
  CMSAltenarEventCard,
  CMSAltenarEventDetails,
  CMSAltenarEventsChampionships,
  CMSAltenarHighlights,
  CMSAltenarLiveOdds,
  CMSAltenarLiveOddsDetails,
  CMSAltenarOutrightEvents,
  CMSAltenarOutrightsChampionships,
  CMSAltenarOverviewDetails,
  CMSAltenarPopularBets,
  CMSAltenarScoreBoard,
  CMSAltenarUpcoming
} from './components/AltenarWidget';
import { News } from '@/types/api/ge-strapi/news';
import { getFallbackLanguage } from '@/helpers/lang';
import {
  isContainingAltenarComponent,
  isContainingPredictionGameComponent,
  isContainingTypeFormComponent
} from '@/helpers/cmsScripts';
import { getProvidersById } from '@/services/providers';

export const CMSDataStoreContextDefaultValues = {
  games: {},
  providers: {}
};
export const CMSDataStoreContext = React.createContext<CMSDataStore>(
  CMSDataStoreContextDefaultValues
);

export const CMSComponentByRef: { [key in CMSComponentRef]: FC<any> } = {
  [CMSComponentRef.ACCORDION]: CMSAccordion,
  [CMSComponentRef.CTA]: CMSCallToAction,
  [CMSComponentRef.DIVIDER]: CMSDivider,
  [CMSComponentRef.GRID]: CMSGrid,
  [CMSComponentRef.IMAGE]: CMSImage,
  [CMSComponentRef.TEXT_CONTENT]: CMSTextContent,
  [CMSComponentRef.TITLE]: CMSTitle,
  [CMSComponentRef.GAME_CATALOG]: CMSGameCatalog,
  [CMSComponentRef.FORM]: CMSForm,
  [CMSComponentRef.SCRIPT]: CMSScript,
  [CMSComponentRef.PREDICTION_GAME]: CMSPredictionGame,
  [CMSComponentRef.ALTENAR_BET_SLIP]: CMSAltenarBestSlip,
  [CMSComponentRef.ALTENAR_EVENT_CARD]: CMSAltenarEventCard,
  [CMSComponentRef.ALTENAR_EVENT_DETAILS]: CMSAltenarEventDetails,
  [CMSComponentRef.ALTENAR_EVENTS_CHAMPIONSHIPS]: CMSAltenarEventsChampionships,
  [CMSComponentRef.ALTENAR_OUTRIGHT_EVENTS]: CMSAltenarOutrightEvents,
  [CMSComponentRef.ALTENAR_OUTRIGHTS_CHAMPIONSHIPS]:
    CMSAltenarOutrightsChampionships,
  [CMSComponentRef.ALTENAR_LIVE_ODDS]: CMSAltenarLiveOdds,
  [CMSComponentRef.ALTENAR_LIVE_ODDS_DETAILS]: CMSAltenarLiveOddsDetails,
  [CMSComponentRef.ALTENAR_HIGHLIGHTS]: CMSAltenarHighlights,
  [CMSComponentRef.ALTENAR_OVERVIEW_DETAILS]: CMSAltenarOverviewDetails,
  [CMSComponentRef.ALTENAR_POPULAR_BETS]: CMSAltenarPopularBets,
  [CMSComponentRef.ALTENAR_SCORE_BOARD]: CMSAltenarScoreBoard,
  [CMSComponentRef.ALTENAR_UPCOMING]: CMSAltenarUpcoming,
  [CMSComponentRef.ALTENAR_BOOSTED_ODDS]: CMSAltenarBoostedOdds
};

type CMSScriptType = 'altenar' | 'typeForm' | 'smarticoMatchXPredictionGame';
export const CMSScripts: Record<string, CMSScriptType> = {
  ALTENAR: 'altenar',
  TYPE_FORM: 'typeForm',
  PREDICTION_GAME: 'smarticoMatchXPredictionGame'
};

export const prepareCMSComponentsProps: (
  data: Partial<CMSPageApiData> | Partial<News>,
  locale: string,
  licence: keyof DomainLicense
) => Promise<any> = async (data, locale, licence) => {
  const includeFrontJs: CMSScriptType[] = [];
  const store: CMSDataStore = CMSDataStoreContextDefaultValues;
  const gameIds: string[] = [];

  if (data.attributes?.content) {
    for (const component of data.attributes.content) {
      switch (component.__component) {
        case CMSComponentRef.GAME_CATALOG:
          // todo
          gameIds.push(
            ...Array.from<string>(
              (component as any).children.map((item: any) => item.uuid)
            )
          );
          break;
      }
    }
  }

  if (isContainingAltenarComponent(data.attributes?.content)) {
    if (licence === 'F') includeFrontJs.push(CMSScripts.ALTENAR);
  }

  if (isContainingTypeFormComponent(data.attributes?.content)) {
    includeFrontJs.push(CMSScripts.TYPE_FORM);
  }

  if (isContainingPredictionGameComponent(data.attributes?.content)) {
    includeFrontJs.push(CMSScripts.PREDICTION_GAME);
  }

  if (gameIds.length) {
    const games = await getGamesById(gameIds, locale, licence);
    // index games by id
    games.forEach((game) => {
      store.games[game.id] = game;
    });

    // get associated providers
    const providerIds = games
      .map((game) => (game.provider as string).split('/')[2])
      .filter(Boolean);
    const providers = await getProvidersById(providerIds, locale);
    // index providers by @id
    providers.forEach((provider) => {
      store.providers[provider['@id']] = provider;
    });
  }

  return { ...data, store, includeFrontJs };
};

export const renderCmsComponent = (data: CMSComponent, index: number) => {
  const Component = CMSComponentByRef[data.__component];
  return Component ? <Component {...data} key={index} /> : <></>;
};

export const renderCmsComponentAltenarBestSlip = (
  data: CMSComponent[],
  betSlipPosition: 'top-left' | 'bottom-right' | undefined = 'bottom-right'
) => {
  return isContainingAltenarComponent(data) ? (
    <CMSAltenarBestSlip
      __component={CMSComponentRef.ALTENAR_BET_SLIP}
      id={1}
      betSlipPosition={betSlipPosition}
    />
  ) : null;
};

export const renderCMSContentComponents = (
  data: CMSComponent[] = [],
  store: CMSDataStore = CMSDataStoreContextDefaultValues
): JSX.Element => {
  return (
    <CMSDataStoreContext.Provider value={store}>
      {data.map(renderCmsComponent)}
      {renderCmsComponentAltenarBestSlip(data)}
    </CMSDataStoreContext.Provider>
  );
};

export const toArrayProps = (
  data?: NumberPerScreen,
  suffix = ''
): (string | number)[] | undefined => {
  if (!data) return undefined;

  const keys: (keyof NumberPerScreen)[] = ['sm', 'md', 'lg'];
  return keys.map((size) => (suffix ? data[size] + suffix : data[size]));
};

const getGamesById: (
  uuids: string[],
  locale: string,
  licence: keyof DomainLicense
) => Promise<Game[]> = async (uuids, locale, licence) => {
  // add + to license
  const licenseQuery = licence ? `&license=${licence + '%2B'}` : '';

  const { serverRuntimeConfig } = getConfig();
  const staticGameListUrl: string = serverRuntimeConfig.STATIC_GAME_LIST_URL;
  const gameServiceUrl = serverRuntimeConfig?.SERVICE_GAME_URL;

  const baseUrl = new URL(
    process.env.IS_BUILDING === 'true' && staticGameListUrl
      ? `${staticGameListUrl}`
      : `${gameServiceUrl}/games`
  );
  const extraQuery = uuids.map((uuid) => 'publicId%5B%5D=' + uuid).join('&');
  const url = `${baseUrl}?visible=true${licenseQuery}&${extraQuery}`;

  let games: Game[] = [];
  try {
    const result = await fetch(url, {
      headers: {
        'Accept-Language': getFallbackLanguage(locale),
        'X-GE-Trace-Id': 'ge-oria-catalog-' + new Date().getTime()
      }
    });
    const data: any = await result.json();

    if (data.error) throw new Error(data.error);

    games = data?.['hydra:member'] || data; // todo: reduce payload

    console.info('CMS-PAGE: LOADING GAMES', {
      count: games?.length,
      url,
      licence,
      baseUrl,
      IS_BUILDING: process.env.IS_BUILDING
    });
  } catch (error) {
    console.error('CMS-PAGE: ERROR LOADING GAMES', {
      error,
      url,
      locale,
      gameServiceUrl,
      IS_BUILDING: process.env.IS_BUILDING
    });
  }
  return games;
};
